interface techType {
  [k: string]: Array<{ name: string; link: string }>;
  preferred: Array<{ name: string; link: string }>;
  learning: Array<{ name: string; link: string }>;
}

export const data: Array<{ name: string; data: techType }> = [
  {
    name: "Languages",
    data: {
      preferred: [
        {
          name: "TypeScript",
          link: "https://img.icons8.com/color/452/typescript.png",
        },
        {
          name: "JavaScript",
          link: "https://d2gdtie5ivbdow.cloudfront.net/media/images/javascript.png",
        },

        { name: "Python", link: "https://img.icons8.com/color/452/python.png" },
      ],
      learning: [
        {
          name: "Java",
          link: "https://img.icons8.com/color/452/java-coffee-cup-logo.png",
        },
        {
          name: "MongoDB",
          link: "https://1000logos.net/wp-content/uploads/2020/08/MongoDB-Emblem.jpg",
        },
        {
          name: "SQL",
          link: "https://cdn2.iconfinder.com/data/icons/programming-50/64/206_programming-sql-data-database-512.png",
        },
        {
          name: "Q#",
          link: "https://qph.cf2.quoracdn.net/main-thumb-ti-2001545-200-rogbkbvvrwtndohttfoyxkxejvympjor.jpeg",
        },
      ],
    },
  },
  {
    name: "Libraries",
    data: {
      preferred: [
        {
          name: "React",
          link: "https://img.icons8.com/color/452/react-native.png",
        },
        {
          name: "Redux",
          link: "https://cdn.iconscout.com/icon/free/png-256/redux-283024.png",
        },
        {
          name: "Ag-Grid",
          link: "https://pbs.twimg.com/profile_images/1362741614909804546/CGoO_TV__400x400.png",
        },
        {
          name: "Jest",
          link: "https://raw.githubusercontent.com/vscode-icons/vscode-icons/master/icons/file_type_jest.svg",
        },
        {
          name: "Cypress",
          link: "https://static-00.iconduck.com/assets.00/cypress-icon-512x512-zi8589rq.png",
        },
        {
          name: "Node",
          link: "https://www.the-guild.dev/blog-assets/nodejs-esm/nodejs_logo.png",
        },
      ],
      learning: [
        {
          name: "Flask",
          link: "https://static-00.iconduck.com/assets.00/programming-language-flask-icon-2048x1826-wf5k5ugs.png",
        },
        {
          name: "D3.js",
          link: "https://raw.githubusercontent.com/d3/d3-logo/master/d3.png",
        },
        {
          name: "Three.js",
          link: "https://canada1.discourse-cdn.com/flex035/uploads/threejs/optimized/2X/e/e4f86d2200d2d35c30f7b1494e96b9595ebc2751_2_992x1000.png",
        },
        {
          name: "RxJS",
          link: "https://rxjs.dev/generated/images/marketing/home/Rx_Logo-512-512.png",
        },
        {
          name: "XState",
          link: "https://d2eip9sf3oo6c2.cloudfront.net/tags/images/000/001/282/square_480/xstate.png",
        },
        {
          name: "Playwright",
          link: "https://playwright.dev/img/playwright-logo.svg",
        },
      ],
    },
  },
  {
    name: "Styling",
    data: {
      preferred: [
        {
          name: "Material UI",
          link: "https://seeklogo.com/images/M/material-ui-logo-5BDCB9BA8F-seeklogo.com.png",
        },
        {
          name: "styled-components",
          link: "https://miro.medium.com/max/480/1*Iohnw2aOQ5EBghVoqKA7VA.png",
        },
        {
          name: "Bootstrap",
          link: "https://img.icons8.com/color/452/bootstrap.png",
        },
      ],
      learning: [
        {
          name: "Semantic UI",
          link: "https://cdn.worldvectorlogo.com/logos/semantic-ui.svg",
        },
        {
          name: "SASS",
          link: "https://1000logos.net/wp-content/uploads/2020/08/Sass-Logo.png",
        },
      ],
    },
  },
  {
    name: "Machine Learning & Data Science",
    data: {
      preferred: [
        {
          name: "Python",
          link: "https://img.icons8.com/color/452/python.png",
        },
        {
          name: "Numpy",
          link: "https://user-images.githubusercontent.com/50221806/86498201-a8bd8680-bd39-11ea-9d08-66b610a8dc01.png",
        },
        {
          name: "Pandas",
          link: "https://pandas.pydata.org/static/img/pandas_mark.svg",
        },
      ],
      learning: [
        {
          name: "TensorFlow",
          link: "https://user-images.githubusercontent.com/13250741/64669187-33b55380-d47e-11e9-8bbf-c02a9d42f9a7.png",
        },
        {
          name: "PyTorch",
          link: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/PyTorch_logo_icon.svg/512px-PyTorch_logo_icon.svg.png",
        },
        {
          name: "R",
          link: "https://www.r-project.org/logo/Rlogo.svg",
        },
      ],
    },
  },
  {
    name: "DevOps",
    data: {
      preferred: [
        {
          name: "GitHub",
          link: "https://img.icons8.com/fluent/452/github.png",
        },
        {
          name: "Bitbucket",
          link: "https://cdn.worldvectorlogo.com/logos/bitbucket-icon.svg",
        },
        {
          name: "VSCode",
          link: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1024px-Visual_Studio_Code_1.35_icon.svg.png",
        },
        {
          name: "AWS",
          link: "https://img.icons8.com/color/452/amazon-web-services.png",
        },
        {
          name: "Jenkins",
          link: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Jenkins_logo.svg/1200px-Jenkins_logo.svg.png",
        },
      ],
      learning: [
        {
          name: "GCP",
          link: "https://img.icons8.com/color/452/google-cloud.png",
        },
        {
          name: "Splunk",
          link: "https://cf-assets.www.cloudflare.com/slt3lc6tev37/5b8uSBmNjgeYVVpZP411Uz/771a30d33c12bda5485e6809ccfd6c6d/Splunk_logo_high_res.png",
        },
        {
          name: "TeamCity",
          link: "https://cdn.worldvectorlogo.com/logos/teamcity-icon.svg",
        },
        {
          name: "Vite",
          link: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Vitejs-logo.svg/1039px-Vitejs-logo.svg.png",
        },
        {
          name: "Nx",
          link: "https://seeklogo.com/images/N/nx-logo-8EB5A23B44-seeklogo.com.png",
        },
      ],
    },
  },
  {
    name: "UX/UI",
    data: {
      preferred: [
        {
          name: "Figma",
          link: "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
        },
        {
          name: "AdobeXD",
          link: "https://cdn.iconscout.com/icon/free/png-512/adobe-xd-1869035-1583159.png",
        },
      ],
      learning: [
        {
          name: "Adobe PhotoShop",
          link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVNSz_1KXXo7jIJqQgI3GdHsLbzVqyMgzNtPoA011pZBkh-EqjnFijejw40rA4rX5HNdk&usqp=CAU",
        },
        {
          name: "Adobe Illustrator",
          link: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Illustrator_CC_icon.png/492px-Illustrator_CC_icon.png",
        },
      ],
    },
  },
];

export const techStackMenu = {
  name: "Tech Stack",
  url: "/",
  subMenu: [
    {
      subName: "Languages",
      items: {
        preferred: ["TypeScript", "JavaScript", "Python"],
        learning: ["Java", "MongoDB", "SQL", "Q#"],
      },
    },
    {
      subName: "Libraries",
      items: {
        preferred: ["React", "Redux", "Jest", "Cypress"],
        learning: ["Ag-Grid", "Node", "Flask", "D3.js", "Three.js"],
      },
    },
    {
      subName: "Styling",
      items: {
        preferred: ["Material UI", "styled-components", "Bootstrap"],
        learning: ["Semantic UI", "SASS"],
      },
    },
    {
      subName: "Machine Learning",
      items: {
        preferred: ["Python", "Numpy", "Pandas"],
        learning: ["TensorFlow", "PyTorch", "R"],
      },
    },
    {
      subName: "DevOps",
      items: {
        preferred: ["GitHub", "Bitbucket", "VSCode"],
        learning: ["GCP", "AWS", "Jenkins", "TeamCity", "Vite", "Nx"],
      },
    },
    {
      subName: "UX/UI",
      items: {
        preferred: ["Figma", "AdobeXd"],
        learning: ["Adobe PhotoShop", "Adobe Illustrator"],
      },
    },
  ],
};

export const menuItems: any = [
  {
    name: "Experience",
    url: "experience",
    subMenu: [
      "JP Morgan Chase & Co.",
      "Capco",
      "Cancer Research UK",
      "CENTURY Tech",
    ],
  },
  {
    name: "Education",
    url: "education",
    subMenu: ["Msc Applied Statistics", "BSc Mathematics & Physics"],
  },
  // {
  //   name: "Creative",
  //   url: "creative",
  //   subMenu: ["Mineral Database"],
  // },
  {
    name: "Certifications",
    url: "certifications",
    subMenu: [
      "Qiskit Global Summer School 2022",
      "IBM Machine Learning",
      "Machine Learning with TensorFlow",
      "Deep Reinforcement Learning",
    ],
  },
];

export const iconMenuItems: any = [
  {
    name: "Tech Stack",
    url: "",
  },
  {
    name: "Experience",
    url: "experience",
  },
  {
    name: "Education",
    url: "education",
  },
  {
    name: "Certifications",
    url: "certifications",
  },
];

export const techList: Array<{ name: string; link: string }> = [
  {
    name: "TypeScript",
    link: "https://img.icons8.com/color/452/typescript.png",
  },
  {
    name: "JavaScript",
    link: "https://d2gdtie5ivbdow.cloudfront.net/media/images/javascript.png",
  },

  { name: "Python", link: "https://img.icons8.com/color/452/python.png" },

  {
    name: "Java",
    link: "https://img.icons8.com/color/452/java-coffee-cup-logo.png",
  },
  {
    name: "MongoDB",
    link: "https://1000logos.net/wp-content/uploads/2020/08/MongoDB-Emblem.jpg",
  },
  {
    name: "SQL",
    link: "https://cdn2.iconfinder.com/data/icons/programming-50/64/206_programming-sql-data-database-512.png",
  },
  {
    name: "Q#",
    link: "https://qph.cf2.quoracdn.net/main-thumb-ti-2001545-200-rogbkbvvrwtndohttfoyxkxejvympjor.jpeg",
  },

  {
    name: "React",
    link: "https://img.icons8.com/color/452/react-native.png",
  },
  {
    name: "Redux",
    link: "https://cdn.iconscout.com/icon/free/png-256/redux-283024.png",
  },
  {
    name: "Ag-Grid",
    link: "https://pbs.twimg.com/profile_images/1362741614909804546/CGoO_TV__400x400.png",
  },
  {
    name: "Jest",
    link: "https://raw.githubusercontent.com/vscode-icons/vscode-icons/master/icons/file_type_jest.svg",
  },
  {
    name: "Cypress",
    link: "https://static-00.iconduck.com/assets.00/cypress-icon-512x512-zi8589rq.png",
  },
  {
    name: "Node",
    link: "https://www.the-guild.dev/blog-assets/nodejs-esm/nodejs_logo.png",
  },

  {
    name: "Flask",
    link: "https://static-00.iconduck.com/assets.00/programming-language-flask-icon-2048x1826-wf5k5ugs.png",
  },
  {
    name: "D3.js",
    link: "https://raw.githubusercontent.com/d3/d3-logo/master/d3.png",
  },
  {
    name: "Three.js",
    link: "https://global.discourse-cdn.com/standard17/uploads/threejs/original/2X/e/e4f86d2200d2d35c30f7b1494e96b9595ebc2751.png",
  },
  {
    name: "RxJS",
    link: "https://rxjs.dev/generated/images/marketing/home/Rx_Logo-512-512.png",
  },
  {
    name: "XState",
    link: "https://d2eip9sf3oo6c2.cloudfront.net/tags/images/000/001/282/square_480/xstate.png",
  },
  {
    name: "Playwright",
    link: "https://playwright.dev/img/playwright-logo.svg",
  },
  {
    name: "Material UI",
    link: "https://seeklogo.com/images/M/material-ui-logo-5BDCB9BA8F-seeklogo.com.png",
  },
  {
    name: "styled-components",
    link: "https://miro.medium.com/max/480/1*Iohnw2aOQ5EBghVoqKA7VA.png",
  },
  {
    name: "Bootstrap",
    link: "https://img.icons8.com/color/452/bootstrap.png",
  },
  {
    name: "Semantic UI",
    link: "https://cdn.worldvectorlogo.com/logos/semantic-ui.svg",
  },
  {
    name: "SASS",
    link: "https://1000logos.net/wp-content/uploads/2020/08/Sass-Logo.png",
  },
  {
    name: "Python",
    link: "https://img.icons8.com/color/452/python.png",
  },
  {
    name: "Numpy",
    link: "https://user-images.githubusercontent.com/50221806/86498201-a8bd8680-bd39-11ea-9d08-66b610a8dc01.png",
  },
  {
    name: "Pandas",
    link: "https://pandas.pydata.org/static/img/pandas_mark.svg",
  },
  {
    name: "TensorFlow",
    link: "https://user-images.githubusercontent.com/13250741/64669187-33b55380-d47e-11e9-8bbf-c02a9d42f9a7.png",
  },
  {
    name: "PyTorch",
    link: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/PyTorch_logo_icon.svg/512px-PyTorch_logo_icon.svg.png",
  },
  {
    name: "R",
    link: "https://www.r-project.org/logo/Rlogo.svg",
  },

  {
    name: "GitHub",
    link: "https://img.icons8.com/fluent/452/github.png",
  },
  {
    name: "Bitbucket",
    link: "https://cdn.worldvectorlogo.com/logos/bitbucket-icon.svg",
  },
  {
    name: "VSCode",
    link: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1024px-Visual_Studio_Code_1.35_icon.svg.png",
  },
  {
    name: "AWS",
    link: "https://img.icons8.com/color/452/amazon-web-services.png",
  },
  {
    name: "Jenkins",
    link: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Jenkins_logo.svg/1200px-Jenkins_logo.svg.png",
  },
  {
    name: "GCP",
    link: "https://img.icons8.com/color/452/google-cloud.png",
  },
  {
    name: "Splunk",
    link: "https://cf-assets.www.cloudflare.com/slt3lc6tev37/5b8uSBmNjgeYVVpZP411Uz/771a30d33c12bda5485e6809ccfd6c6d/Splunk_logo_high_res.png",
  },
  {
    name: "TeamCity",
    link: "https://cdn.worldvectorlogo.com/logos/teamcity-icon.svg",
  },
  {
    name: "Vite",
    link: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Vitejs-logo.svg/1039px-Vitejs-logo.svg.png",
  },
  {
    name: "Nx",
    link: "https://seeklogo.com/images/N/nx-logo-8EB5A23B44-seeklogo.com.png",
  },
  {
    name: "Figma",
    link: "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
  },
  {
    name: "AdobeXD",
    link: "https://cdn.iconscout.com/icon/free/png-512/adobe-xd-1869035-1583159.png",
  },
  {
    name: "Adobe PhotoShop",
    link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVNSz_1KXXo7jIJqQgI3GdHsLbzVqyMgzNtPoA011pZBkh-EqjnFijejw40rA4rX5HNdk&usqp=CAU",
  },
  {
    name: "Adobe Illustrator",
    link: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Illustrator_CC_icon.png/492px-Illustrator_CC_icon.png",
  },
];
